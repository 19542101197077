<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="p-5">
    <h1>Организации</h1>
    <table class="table">
      <tr>
        <th>ID</th>
        <th>Название</th>
        <th>Полное название</th>
        <th>По умолчанию</th>
        <th>Активность</th>
      </tr>
      <tr v-for="org in orgs" :key="org.id">
        <td><router-link :to="{ name: 'fin/settings/orgs/detail', params: { id: org.id } }">{{ org.id }}</router-link></td>
        <td><router-link :to="{ name: 'fin/settings/orgs/detail', params: { id: org.id } }">{{ org.title }}</router-link></td>
        <td>{{ org.full_title }}</td>
        <td>{{ org.default | yesno }}</td>
        <td>{{ org.active | yesno }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Orgs',
  data () {
    return {
      isLoading: true,
      orgs: []
    }
  },
  watch: {
    $route () {
      this.checkDetail()
    }
  },
  async created () {
    this.checkDetail()
    this.orgs = await this.$api.query('fin/org')
    this.isLoading = false
  },
  methods: {
    checkDetail () {
      if (this.$route.params.id) {
        this.$store.dispatch('openPanel', { path: this.$route.path })
      }
    }
  }
}
</script>
