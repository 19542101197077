<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
  <div v-else>
    <b-form>
      <b-form-group label="Название">
        <b-form-input v-model="org.title"></b-form-input>
      </b-form-group>
      <b-form-group label="Полное название">
        <b-form-input v-model="org.full_title"></b-form-input>
      </b-form-group>
      <b-form-group label="Активность">
        <b-form-checkbox v-model="org.active" switch></b-form-checkbox>
      </b-form-group>
      <b-form-group label="По умолчанию">
        <b-form-checkbox v-model="org.default" switch></b-form-checkbox>
      </b-form-group>
      <b-form-group label="ИНН">
        <b-form-input v-model="org.inn"></b-form-input>
      </b-form-group>
      <b-form-group label="КПП">
        <b-form-input v-model="org.kpp"></b-form-input>
      </b-form-group>
      <b-form-group label="Юридический адрес">
        <b-form-input v-model="org.legal_address"></b-form-input>
      </b-form-group>
      <b-form-group label="Город">
        <b-form-input v-model="org.legal_city"></b-form-input>
      </b-form-group>
      <b-form-group label="Область">
        <b-form-input v-model="org.legal_region"></b-form-input>
      </b-form-group>
      <b-form-group label="Индекс">
        <b-form-input v-model="org.legal_zip"></b-form-input>
      </b-form-group>
      <b-form-group label="Страна">
        <b-form-input v-model="org.legal_country"></b-form-input>
      </b-form-group>
      <b-form-group label="Веб-сайт">
        <b-form-input v-model="org.website"></b-form-input>
      </b-form-group>
      <b-form-group label="E-mail">
        <b-form-input v-model="org.email"></b-form-input>
      </b-form-group>
      <b-form-group label="Телефон">
        <b-form-input v-model="org.phone"></b-form-input>
      </b-form-group>
      <b-form-group label="Доп. телефон">
        <b-form-input v-model="org.phone2"></b-form-input>
      </b-form-group>
      <b-form-group label="Префикс для счета">
        <b-form-input v-model="org.invoice_prefix"></b-form-input>
      </b-form-group>
      <b-form-group label="ФИО руководителя">
        <b-form-input v-model="org.ceo_name"></b-form-input>
      </b-form-group>
      <b-form-group label="Должность руководителя">
        <b-form-input v-model="org.ceo_post"></b-form-input>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'OrgEdit',
  props: ['id'],
  data () {
    return {
      isLoading: true,
      error: '',
      org: []
    }
  },
  computed: {
    currentId () {
      return this.id || this.$route.params.id
    }
  },
  watch: {
    $route () {
      this.$vuedals.close()
    }
  },
  async created () {
    try {
      this.org = await this.$api.query('fin/org/' + this.currentId)
    } catch (err) {
      this.error = err
    }

    this.isLoading = false
  }
}
</script>
