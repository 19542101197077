<template>
  <div>
    <div class="mx-4">
      <div class="d-flex justify-content-end my-3">
        <router-link class="btn btn-primary" :to="{ name: 'fin/invoices/create' }">Создать</router-link>
      </div>
      <SmartTable
        :sourceUrl="sourceUrl"
        :extSource="extSource"
        :columns="columns" />
    </div>
  </div>
</template>

<script>
import SmartTable from '@/components/SmartTable'
import RouterLinkColumn from '@/components/columns/RouterLinkColumn'
import moment from 'moment'

export default {
  name: 'Invoices',
  components: {
    SmartTable
  },
  data () {
    return {
      sourceUrl: 'fin/invoice',
      extSource: {
        user_list: 'user'
      },
      columns: [
        {
          field: 'source',
          headerText: 'Источник'
        },
        {
          field: 'external_id',
          headerText: 'ID'
        },
        {
          field: 'number',
          headerText: 'Номер',
          template: () => {
            return {
              template: RouterLinkColumn,
              data: { routerName: 'fin/invoices/detail', routerParams: { id: 'id' } }
            }
          }
        },
        {
          field: 'sum',
          headerText: 'Сумма',
          textAlign: 'Right'
        },
        {
          field: 'currency',
          headerText: 'Валюта'
        },
        {
          field: 'title',
          headerText: 'Название'
        },
        {
          field: 'responsible_id',
          headerText: 'Ответственный',
          dataSource: 'user_list',
          foreignKeyValue: 'name',
          foreignKeyField: 'bitrix24_user_id'
        },
        {
          field: 'status',
          headerText: 'Статус'
        },
        {
          field: 'comment',
          headerText: 'Комментарий'
        },
        {
          field: 'created_at',
          headerText: 'Дата создания',
          valueAccessor (field, data, column) {
            return data[field] ? moment(data[field] * 1000).format('DD.MM.YYYY hh:mm:ss') : ''
          }
        },
        {
          field: 'date_bill',
          headerText: 'Дата выставления',
          valueAccessor (field, data, column) {
            return data[field] ? moment(data[field] * 1000).format('DD.MM.YYYY hh:mm:ss') : ''
          }
        },
        {
          field: 'date_pay_before',
          headerText: 'Срок оплаты',
          valueAccessor (field, data, column) {
            return data[field] ? moment(data[field] * 1000).format('DD.MM.YYYY hh:mm:ss') : ''
          }
        },
        {
          field: 'date_payed',
          headerText: 'Дата оплаты',
          valueAccessor (field, data, column) {
            return data[field] ? moment(data[field] * 1000).format('DD.MM.YYYY hh:mm:ss') : ''
          }
        }
      ]
    }
  },
  watch: {
    $route () {
      this.checkDetail()
    }
  },
  created () {
    this.checkDetail()
  },
  methods: {
    checkDetail () {
      if (this.$route.params.id) {
        this.$store.dispatch('openPanel', { path: this.$route.path })
      }
    }
  }
}
</script>
