<template>
  <router-link :to="routerTo"
    v-html="dataFormatted"></router-link>
</template>

<script>
export default {
  name: 'RouterLinkColumn',
  data () {
    return {
      data: {}
    }
  },
  computed: {
    columnField () {
      return this.data.column.foreignKeyField || this.data.column.field
    },
    dataFormatted () {
      return this.data.column.valueAccessor(this.columnField, this.data, this.data.column)
    },
    routerTo () {
      const params = this.data.routerParams
      for (const param in params) {
        if (typeof params[param] !== 'string') continue
        params[param] = this.data[params[param]] || params[param]
      }

      return { name: this.data.routerName, params }
    }
  }
}
</script>
