<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="p-5">
    <h1>Счета организации</h1>
    <table class="table">
      <tr>
        <th>ID</th>
        <th>Название</th>
        <th>Тип</th>
        <th>Организация</th>
        <th>Номер счета</th>
      </tr>
      <tr v-for="invoice in invoices" :key="invoice.id">
        <td><router-link :to="{ name: 'fin/settings/org-invoices/detail', params: { id: invoice.id } }">{{ invoice.id }}</router-link></td>
        <td><router-link :to="{ name: 'fin/settings/org-invoices/detail', params: { id: invoice.id } }">{{ invoice.title }}</router-link></td>
        <td>{{ invoice.type }}</td>
        <td><router-link :to="{ name: 'fin/settings/orgs/detail', params: { id: invoice.org_id } }">{{ invoice.org_title }}</router-link></td>
        <td>{{ invoice.number }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OrgInvoices',
  data () {
    return {
      isLoading: true,
      invoices: []
    }
  },
  watch: {
    $route () {
      this.checkDetail()
    }
  },
  async created () {
    this.checkDetail()
    this.invoices = await this.$api.query('fin/org/invoice')
    this.isLoading = false
  },
  methods: {
    checkDetail () {
      if (this.$route.params.id) {
        this.$store.dispatch('openPanel', { path: this.$route.path })
      }
    }
  }
}
</script>
