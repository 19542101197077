<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else>
    <b-form>
      <div class="row">
        <div class="col-6">
          <b-form-group label="Сумма">
            <b-form-input v-model="operation.amount"></b-form-input>
            <b-form-invalid-feedback :state="validateAmount">
              Общая сумма отличается от <a href="" @click.prevent="fixTotalSum" title="Исправить">{{ totalSum | money }}</a>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Дата оплаты"><b-form-input v-model="operation.executed_at"></b-form-input></b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <b-form-group label="Организация">
            <b-form-select v-model="operation.org_id" :options="orgs"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Счет организации">
            <b-form-select v-model="operation.org_invoice_id" :options="orgInvoices.filter(el => el.org_id === operation.org_id)"></b-form-select>
          </b-form-group>
        </div>
      </div>
      <b-form-group label="Назначение платежа">
        <b-form-textarea v-model="operation.description"></b-form-textarea>
      </b-form-group>
      <hr>

      <h3 class="rx-title">Связанные счета</h3>
      <router-link v-for="invoice in invoices" :key="invoice.id" :to="{ name: 'fin/invoices/detail', params: { id: invoice.id } }">{{ invoice.title }}</router-link>
      <hr>

      <table class="table">
        <tr>
          <th>Название</th>
          <th>Расход</th>
          <th>Поступление</th>
          <th>Категория</th>
          <th>Связь</th>
        </tr>
        <tr v-for="item in items" :key="item.id">
          <td><b-form-input v-model="item.title"></b-form-input></td>
          <td><b-form-input v-model="item.outcome"></b-form-input></td>
          <td><b-form-input v-model="item.income"></b-form-input></td>
          <td>
            <select class="form-control" v-model="item.category_id">
              <option :value="null">Нет категории</option>
              <optgroup v-for="group in categories" :key="group.id" :label="group.title">
                <option v-for="cat in group.children" :key="cat.id" :value="cat.id">{{ cat.title }}</option>
              </optgroup>
            </select>
          </td>
          <td>
            <div>
              <v-select v-model="item.invoice_id" label="title" placeholder="Счет" :filterable="false" :reduce="el => el.id" :options="invoiceOptions" @search="onInvoiceSearch"></v-select>
            </div>
            <div>
              <v-select v-model="item.invoice_product_id" label="title" placeholder="Товар" :filterable="false" :reduce="el => el.id" :options="invoiceProductOptions" @search="(search, loading) => onInvoiceProductSearch(item, search, loading)"></v-select>
            </div>
          </td>
        </tr>
      </table>
      <a href="" @click.prevent="addItem">Добавить строку</a>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'OperationEdit',
  props: ['id'],
  data () {
    return {
      isLoading: true,
      operation: [],
      items: [],
      orgs: [],
      orgInvoices: [],
      categories: [],
      invoiceOptions: [],
      invoiceProductOptions: []
    }
  },
  computed: {
    currentId () {
      return this.id || this.$route.params.id
    },
    totalSum () {
      return this.items.reduce((acc, val) => acc + (val.income ? parseFloat(val.income) : 0) - (val.outcome ? parseFloat(val.outcome) : 0), 0)
    },
    validateAmount () {
      return this.totalSum === parseFloat(this.operation.amount)
    },
    invoices () {
      const result = {}
      this.items.forEach(el => {
        result[el.invoice_id] = {
          id: el.invoice_id,
          title: el.invoice_title
        }
      })
      return Object.values(result)
    }
  },
  watch: {
    $route () {
      this.$vuedals.close()
    }
  },
  async created () {
    const orgs = await this.$api.query('fin/org')
    orgs.forEach(el => { this.orgs.push({ value: el.id, text: el.title }) })

    const orgInvoices = await this.$api.query('fin/org/invoice')
    orgInvoices.forEach(el => { this.orgInvoices.push({ value: el.id, text: el.title, org_id: el.org_id }) })

    this.categories = await this.$api.query('fin/category')

    this.operation = await this.$api.query('fin/operation/' + this.currentId)
    this.items = await this.$api.query('fin/operation-item', { operation_id: this.currentId })

    this.items.forEach(el => {
      if (!this.invoiceOptions.find(el2 => el2.id === el.invoice_id)) {
        this.invoiceOptions.push({ id: el.invoice_id, title: el.invoice_title })
      }
      this.invoiceProductOptions.push({ id: el.invoice_product_id, title: el.invoice_product_title })
    })

    this.isLoading = false
  },
  methods: {
    addItem () {
      this.items.push({})
    },
    fixTotalSum () {
      this.operation.amount = this.totalSum
    },
    async onInvoiceSearch (search, loading) {
      if (search.length) {
        loading(true)

        this.invoiceOptions = await this.$api.query('fin/invoice/search', { q: search })

        loading(false)
      }
    },
    async onInvoiceProductSearch (item, search, loading) {
      if (search.length && item.invoice_id > 0) {
        loading(true)

        this.invoiceProductOptions = await this.$api.query('fin/invoice/product/search', { q: search, invoice_id: item.invoice_id })

        loading(false)
      }
    }
  }
}
</script>

<style>
.vs--single .vs__selected {
  white-space: nowrap;
}
</style>
