<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="cashflow-content">
    <div class="cashflow-filter">
      <b-form :inline="true">
        <b-form-group>
          <b-form-select v-model="org" :options="orgOptions" @change="filter"></b-form-select>
        </b-form-group>
        <b-form-group v-if="org">
          <b-form-select v-model="orgInvoice" :options="orgInvoiceOptions.filter(el => !el.org_id || el.org_id === org)" @change="filter"></b-form-select>
        </b-form-group>
        <b-form-group>
          <b-form-select v-model="period" :options="periodOptions" @change="filter"></b-form-select>
        </b-form-group>
        <b-form-group v-if="period === 'quarter'">
          <b-form-select v-model="quarter" :options="quarterOptions" @change="filter"></b-form-select>
        </b-form-group>
        <b-form-group v-if="period === 'quarter' || period === 'year'">
          <b-form-select v-model="year" :options="yearOptions" @change="filter"></b-form-select>
        </b-form-group>
      </b-form>
      <div v-if="calcSum" class="cashflow-calcsum">Сальдо выбранных ячеек: {{ calcSum | finmoney }}</div>
    </div>

    <div class="table-scrollable">
      <table class="table cashflow">
        <thead>
          <tr>
            <th>#</th>
            <th class="grey">Нач. остаток</th>
            <th v-for="col in cols" :key="col">
              <a @click="showPanel({ dateGroup: col })">{{ col | month | capitalize }}</a>
            </th>
            <th class="grey">Итого</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="grey">Нач. остаток</td>
            <td class="grey"><span v-if="rest.total && rest.total.total">{{ rest.total.total | finmoney }}</span><span v-else>0</span></td>
            <td class="grey" v-for="col in cols" :key="col">{{ rest.total[col] | finmoney }}</td>
            <td class="grey"><span v-if="rest.total && rest.total.total">{{ rest.total.total | finmoney }}</span><span v-else>0</span></td>
          </tr>
        </tbody>
        <tbody v-if="data.empty">
          <tr>
            <td>Без категории</td>
            <td class="grey"><span v-if="rest.empty">{{ rest.empty.total | finmoney }}</span><span v-else>0</span></td>
            <td v-for="col in cols" :key="col" @click="calcCell($event, 'empty_' + col, data.empty[col])" :class="{ grey: Object.keys(calc).includes('empty_' + col) }">
              <a @click="showPanel({ dateGroup: col, category: 'empty' })">
                {{ data.empty[col] || 0 | finmoney }}
              </a>
            </td>
            <td class="grey"><span v-if="data.empty">{{ data.empty.total + (rest.empty ? rest.empty.total : 0) | finmoney }}</span><span v-else>0</span></td>
          </tr>
        </tbody>
        <tbody v-for="type in types" :key="type.id">
          <tr>
            <th class="grey">{{ type.title }}</th>
            <th class="grey">{{ type.children.reduce((acc, val) => rest[val.id] && rest[val.id].total ? acc + rest[val.id].total : acc, 0) | finmoney }}</th>
            <th class="grey" v-for="col in cols" :key="col">{{ type.children.reduce((acc, val) => data[val.id] && data[val.id][col] ? acc + data[val.id][col] : acc, 0) | finmoney }}</th>
            <th class="grey">{{ type.children.reduce((acc, val) => data[val.id] && data[val.id].total ? acc + data[val.id].total + (rest[val.id] ? rest[val.id].total : 0) : acc, 0) | finmoney }}</th>
          </tr>
          <tr v-for="cat in type.children" :key="cat.id">
            <td>{{ cat.title }}</td>
            <td class="grey"><span v-if="rest[cat.id]">{{ rest[cat.id].total | finmoney }}</span><span v-else>0</span></td>
            <td v-for="col in cols" :key="col" @click="calcCell($event, cat.id + '_' + col, data[cat.id][col])" :class="{ grey: Object.keys(calc).includes(cat.id + '_' + col) }">
              <a v-if="data[cat.id] && data[cat.id][col]" @click="showPanel({ dateGroup: col, category: cat.id })">
                {{ data[cat.id][col] | finmoney }}
              </a>
              <span v-else>0</span>
            </td>
            <td class="grey">{{ data[cat.id] ? data[cat.id].total + (rest[cat.id] ? rest[cat.id].total : 0) : 0 | finmoney }}</td>
          </tr>
        </tbody>
        <tbody v-if="data.total">
          <tr>
            <td class="balihai">Изменение денег за месяц</td>
            <td class="balihai"><span v-if="rest.total && rest.total.total">{{ rest.total.total | finmoney }}</span><span v-else>0</span></td>
            <td class="balihai" v-for="col in cols" :key="col">{{ data.total[col] || 0 | finmoney }}</td>
            <td class="balihai">{{ data.total.total + rest.total.total || 0 | finmoney }}</td>
          </tr>
        </tbody>
        <tbody v-if="data.total">
          <tr>
            <td>Денег на конец месяца</td>
            <td class="grey"></td>
            <td v-for="col in cols" :key="col">{{ data.total[col] + rest.total[col] || 0 | finmoney }}</td>
            <td class="grey">{{ data.total.total + rest.total.total || 0 | finmoney }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import OperationItemsCompact from './OperationItemsCompact'

export default {
  name: 'Cashflow',
  data () {
    return {
      isLoading: true,
      cols: [],
      types: [],
      data: [],
      rest: [],
      calc: {},
      org: null,
      orgOptions: [
        { value: null, text: 'Все организации' }
      ],
      orgInvoice: null,
      orgInvoiceOptions: [
        { value: null, text: 'Все счета' }
      ],
      period: null,
      periodOptions: [
        { value: null, text: 'Последние 12 месяцев' },
        { value: 'quarter', text: 'Квартал' },
        { value: 'year', text: 'Год' }
      ],
      quarter: (new Date()).getMonth() / 3 + 1,
      quarterOptions: [
        { value: 1, text: 'Первый' },
        { value: 2, text: 'Второй' },
        { value: 3, text: 'Третий' },
        { value: 4, text: 'Четвертый' }
      ],
      year: (new Date()).getFullYear(),
      yearOptions: []
    }
  },
  computed: {
    calcSum () {
      return Object.values(this.calc).reduce((a, b) => a + b, 0)
    }
  },
  async created () {
    this.getQueryParams()

    const orgs = await this.$api.query('fin/org')
    orgs.forEach(el => {
      this.orgOptions.push({ value: el.id, text: el.title })
    })

    const orgInvoices = await this.$api.query('fin/org/invoice')
    orgInvoices.forEach(el => {
      this.orgInvoiceOptions.push({ value: el.id, text: el.title, org_id: el.org_id })
    })

    this.fetchData(window.location.search)
  },
  methods: {
    async fetchData (query = '') {
      this.isLoading = true

      this.types = await this.$api.query('fin/category')

      const cashflowResult = await this.$api.query('fin/analytics/cashflow' + query)
      this.cols = cashflowResult.cols
      this.data = cashflowResult.data
      this.rest = cashflowResult.rest

      this.yearOptions = []
      cashflowResult.years.forEach(el => {
        this.yearOptions.push({ value: el, text: el })
      })

      this.isLoading = false
    },
    getQueryParams () {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      if (params.quarter && params.year) {
        this.period = 'quarter'
        this.quarter = params.quarter
        this.year = params.year
      } else if (params.year) {
        this.period = 'year'
        this.year = params.year
      }
      if (params.org) {
        this.org = parseInt(params.org)
        if (params.org_invoice) {
          this.orgInvoice = parseInt(params.org_invoice)
        }
      }
    },
    showPanel (props) {
      this.$vuedals.open({ title: 'Operations', component: OperationItemsCompact, props })
    },
    calcCell (e, key, sum) {
      if (!sum || e.target.tagName.toLowerCase() === 'a') return
      if (Object.keys(this.calc).includes(key)) {
        Vue.delete(this.calc, key)
      } else {
        Vue.set(this.calc, key, sum || 0)
      }
    },
    filter () {
      let query = ''
      if (this.period === 'quarter') {
        query = 'quarter=' + this.quarter + '&year=' + this.year
      } else if (this.period === 'year') {
        query = 'year=' + this.year
      }
      if (this.org) {
        query += '&org=' + this.org
        if (this.orgInvoice) {
          query += '&org_invoice=' + this.orgInvoice
        }
      }
      if (query.substr(0, 1) === '&') {
        query = query.substr(1)
      }

      this.fetchData('?' + query)

      if (history.pushState) {
        const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + (query ? '?' + query : '')
        window.history.pushState({ path: newUrl }, '', newUrl)
      }
    }
  }
}
</script>

<style scoped>
.cashflow-content {
  padding: 50px 40px;
}
.table-scrollable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
table.cashflow {
  max-width: 100%;
  width: 100% !important;
  margin: 0 !important;
  table-layout: fixed;
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0;
}
table.cashflow > thead:first-child tr th {
  color: var(--text-color);
}
table.cashflow > thead tr th {
  width: 120px;
  text-align: left;
}
table.cashflow > thead tr th:first-child {
  width: 350px;
}
.table-scrollable > table > thead > tr > th,
.table-scrollable > table > tbody > tr > th,
.table-scrollable > table > tbody > tr > td {
  background: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 11px 0;
  padding-right: 10px;
  text-align: right;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: var(--title-color);
  border: none;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
table.cashflow thead,
table.cashflow tbody {
  border: none;
}
table.cashflow thead tr:first-child th {
  text-align: center;
  padding-right: 0;
}
table.cashflow thead tr th:first-child,
table.cashflow tbody tr th:first-child,
table.cashflow tbody tr td:first-child,
table.cashflow thead tr th:last-child,
table.cashflow tbody tr th:last-child,
table.cashflow tbody tr td:last-child {
  position: sticky;
  left: 0;
  background-color: #ffffff;
}
table.cashflow thead tr th:last-child,
table.cashflow tbody tr th:last-child,
table.cashflow tbody tr td:last-child {
  left: unset;
  right: 0;
}
table.cashflow thead tr th:first-child,
table.cashflow tbody tr th:first-child,
table.cashflow tbody tr td:first-child {
  text-align: left;
  padding: 11px 10px;
}
table.cashflow th.grey,
table.cashflow td.grey {
  background-color: #F0F4FA !important;
  border: none;
}
table.cashflow th.balihai,
table.cashflow td.balihai {
  background-color: var(--text-color) !important;
  color: #ffffff;
  border: none;
}
table.cashflow th a:hover,
table.cashflow td a:hover {
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}
.cashflow-calcsum {
  font-size: 11px;
  line-height: 13px;
  color: var(--text-color);
}
.cashflow-filter {
  display: flex;
  margin-bottom: 27px;
  align-items: center;
}
.cashflow-filter select {
  margin-right: 10px;
}
</style>
