<template>
  <div v-if="isEdit" v-on-clickaway="endEdit">
    <select v-if="type === 'select'" ref="editable" v-model="innerValue">
      <option v-for="item in list" :key="item.value" :value="item.value">{{ item.text }}</option>
    </select>
    <select v-else-if="type === 'multiselect'" ref="editable" v-model="innerValue" multiple>
      <option v-for="item in list" :key="item.value" :value="item.value">{{ item.text }}</option>
    </select>
    <input v-else ref="editable" type="text" v-model.lazy="innerValue" @keyup.enter="endEdit" />
  </div>
  <p v-else @click="startEdit">
    <span v-if="Array.isArray(innerValue)">{{ innerValueTitles }}</span>
    <span v-else>{{ innerValue }}</span>
  </p>
</template>

<script>
import Vue from 'vue'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    list: {
      type: Array
    }
  },
  directives: {
    onClickaway
  },
  data () {
    return {
      isEdit: false,
      innerValue: ''
    }
  },
  computed: {
    listeners () {
      return { ...this.$listeners, input: this.onInput }
    },
    innerValueTitles () {
      if (!Array.isArray(this.innerValue)) return ''
      return this.list.filter(el => this.innerValue.includes(el.value)).map(el => el.text).join(', ')
    }
  },
  watch: {
    innerValue () {
      if (!this.isEdit) return
      this.onInput()
    }
  },
  mounted () {
    this.innerValue = this.value
  },
  methods: {
    onInput (e) {
      this.$emit('input', this.innerValue)
    },
    startEdit () {
      this.isEdit = true
      Vue.nextTick(() => {
        this.$refs.editable.focus()
      })
    },
    endEdit () {
      this.isEdit = false
    }
  }
}
</script>

<style scoped>
p,
p span {
  display: block;
  cursor: text;
}
p span:empty {
  width: 100%;
  height: 20px;
}
input[type="text"],
select {
  background-color: var(--grey-color);
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
}
</style>
