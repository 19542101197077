<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="message">
    <div class="alert alert-success">{{ message }}</div>
    <button class="btn btn-primary" @click="message = ''">Добавить еще</button>
  </div>
  <div v-else>
    <b-form @submit="submit">
      <b-form-group label="Название">
        <b-form-input
          v-model="title"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Описание">
        <b-form-input
          v-model="desc"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Тип">
        <b-form-select v-model="type" :options="types" required></b-form-select>
      </b-form-group>
      <b-form-group label="Активный">
        <b-form-checkbox :switch="true" v-model="active"></b-form-checkbox>
      </b-form-group>
      <b-form-group label="Доход">
        <b-form-checkbox :switch="true" v-model="income"></b-form-checkbox>
      </b-form-group>
      <b-form-group label="Расход">
        <b-form-checkbox :switch="true" v-model="outcome"></b-form-checkbox>
      </b-form-group>
      <button type="submit" class="btn btn-primary">Создать</button>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'CategoryNew',
  data () {
    return {
      isLoading: true,
      title: '',
      desc: '',
      type: '',
      active: true,
      income: false,
      outcome: false,
      types: [],
      message: ''
    }
  },
  async created () {
    const result = await this.$api.query('fin/category')

    result.forEach(el => {
      if (!this.type) {
        this.type = el.id
      }
      this.types.push({ value: el.id, text: el.title })
    })

    this.isLoading = false
  },
  methods: {
    async submit () {
      this.isLoading = true

      await this.$api.post('fin/category', {
        title: this.title,
        desc: this.desc,
        type: this.type,
        active: this.active,
        income: this.income,
        outcome: this.outcome
      })
      this.message = 'Категория успешно создана!'

      this.isLoading = false
    }
  }
}
</script>
