<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else class="categories-wrapper">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="title-color">Категории доходов и расходов</h1>
      <div>
        <button class="btn btn-primary" @click="showPanel">Создать</button>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Название</th>
          <th>Активный</th>
          <th>Доход</th>
          <th>Расход</th>
          <th>Описание</th>
          <th>Разделы товаров</th>
          <th></th>
        </tr>
      </thead>
      <draggable tag="tbody" draggable=".draggable" group="category" v-for="type in types" :key="type.id" v-model="type.children"
        @change="save" ghost-class="bg-light" filter="input" :prevent-on-filter="false">
        <tr slot="header"><th colspan="5">{{ type.title }}</th></tr>
        <tr class="draggable" v-for="cat in type.children" :key="cat.id">
          <td><b-icon-list></b-icon-list></td>
          <td><Editable v-model="cat.title" @input="save" /></td>
          <td><b-checkbox v-model="cat.active" @change="save" :switch="true"></b-checkbox></td>
          <td><b-checkbox v-model="cat.income" @change="save" :switch="true"></b-checkbox></td>
          <td><b-checkbox v-model="cat.outcome" @change="save" :switch="true"></b-checkbox></td>
          <td><Editable v-model="cat.desc" @input="save" /></td>
          <td><Editable type="multiselect" :list="productSections" v-model="cat.product_sections" @input="save" /></td>
          <td><b-icon-trash @click="remove(cat.id)"></b-icon-trash></td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import Editable from '@/components/Editable'
import draggable from 'vuedraggable'
import CategoryNew from './CategoryNew'
import NotifyMixin from '@/mixins/NotifyMixin.js'

export default {
  name: 'CategoryList',
  components: {
    Editable,
    draggable
  },
  mixins: [NotifyMixin],
  data () {
    return {
      isLoading: true,
      types: [],
      productSections: []
    }
  },
  async created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      this.types = await this.$api.query('fin/category')

      const productSections = await this.$api.query('sale/product-section')
      productSections.forEach(el => {
        this.productSections.push({ value: el.id, text: el.title })
      })

      this.isLoading = false
    },
    async save () {
      this.fixOrders()

      await this.$api.post('fin/category/save', this.types)

      this.notifySuccess('Настройки сохранены', 'Категории расходов и доходов успешно сохранены')
    },
    fixOrders () {
      for (let i = 0; i < this.types.length; i++) {
        if (!this.types[i].children) {
          continue
        }
        let order = 1
        for (let j = 0; j < this.types[i].children.length; j++) {
          Vue.set(this.types[i].children[j], 'order', order)
          order++
        }
      }
    },
    showPanel () {
      this.$vuedals.open({
        title: 'Новая категория',
        component: CategoryNew
      })
    },
    remove (catId) {
      let found = false
      for (let i = 0; i < this.types.length; i++) {
        if (found) break
        if (!this.types[i].children) {
          continue
        }
        for (let j = 0; j < this.types[i].children.length; j++) {
          if (found) break
          if (this.types[i].children[j].id === catId) {
            Vue.delete(this.types[i].children, j)
            found = true
          }
        }
      }

      this.$api.delete('fin/category/' + catId)
    }
  }
}
</script>

<style scoped>
.categories-wrapper {
  padding: 40px;
}
h1 {
  margin-bottom: 20px;
  display: block;
}
</style>
