<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
  <div v-else>
    <b-form>
      <b-form-group label="Название">
        <b-form-input v-model="invoice.title"></b-form-input>
      </b-form-group>
      <b-form-group label="Организация">
        <b-form-select v-model="invoice.org_id" :options="orgs.map(el => { return { value: el.id, text: el.title } })"></b-form-select>
      </b-form-group>
      <b-form-group label="Тип">
        <b-form-input v-model="invoice.type"></b-form-input>
      </b-form-group>
      <b-form-group label="Номер счета">
        <b-form-input v-model="invoice.number"></b-form-input>
      </b-form-group>
      <b-form-group label="Наименование банка">
        <b-form-input v-model="invoice.bank_name"></b-form-input>
      </b-form-group>
      <b-form-group label="БИК банка">
        <b-form-input v-model="invoice.bic"></b-form-input>
      </b-form-group>
      <b-form-group label="Корр. счет банка">
        <b-form-input v-model="invoice.corr_number"></b-form-input>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'OrgInvoiceEdit',
  props: ['id'],
  data () {
    return {
      isLoading: true,
      error: '',
      invoice: [],
      orgs: []
    }
  },
  computed: {
    currentId () {
      return this.id || this.$route.params.id
    }
  },
  watch: {
    $route () {
      this.$vuedals.close()
    }
  },
  async created () {
    try {
      this.orgs = await this.$api.query('fin/org')
      this.invoice = await this.$api.query('fin/org/invoice/' + this.currentId)
    } catch (err) {
      this.error = err
    }

    this.isLoading = false
  }
}
</script>
