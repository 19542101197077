<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
  <div v-else>
    <div class="row">
      <div class="col-7">
        <div v-if="isLoadingDoc"><Loader /></div>
        <div v-else-if="errorDoc" class="alert alert-danger">{{ errorDoc }}</div>
        <div v-else class="doc-wrapper">
          <b-progress v-if="pdfGeneratePercent" class="doc-pdf-progress" :value="pdfGeneratePercent" striped animated></b-progress>
          <button v-else class="btn btn-primary doc-pdf-btn" @click="generatePdf">Скачать в PDF</button>
          <div v-html="doc" class="doc-template"></div>
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="true"
            pdf-format="a4"
            pdf-content-width="210mm"
            :pdf-quality="2"
            :filename="invoice.title"
            :manual-pagination="true"
            :html-to-pdf-options="{ margin: [15, 0], html2canvas: { scale: 2 }, enableLinks: true, pagebreak: { mode: ['avoid-all', 'css', 'legacy'] } }"
            @progress="onPdfGenerateProgress($event)"
          >
            <section slot="pdf-content" class="doc-template doc-template-pdf" v-html="doc"></section>
          </vue-html2pdf>
        </div>
      </div>
      <div class="col-5">
        <b-form>
          <div class="row">
            <div class="col-9">
              <b-form-group v-if="invoice.source_link" label="Источник">
                <a :href="invoice.source_link" target="_blank">{{ invoice.source_link }}</a>
              </b-form-group>
            </div>
            <div class="col-3 text-right">
              <button v-if="invoice.can_send_on_approval" class="btn btn-secondary" @click.stop.prevent="sendOnApproval">На согласование</button>
              <button v-if="invoice.can_approve" class="btn btn-secondary" @click.stop.prevent="approve">Согласовать</button>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <b-form-group label="Шаблон">
                <b-form-select v-model="invoice.template_id" :options="templateOptions"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Основание"><b-form-input v-model="invoice.basis"></b-form-input></b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Номер"><b-form-input v-model="invoice.number" :disabled="!!this.currentId"></b-form-input></b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Название"><b-form-input v-model="invoice.title"></b-form-input></b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Статус">
                <template #label>
                  Статус <span v-if="invoice.is_expired" class="text-danger">Просрочен</span>
                </template>
                <b-form-input v-model="invoice.status_title" disabled></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Ответственный">
                <EntitySelect v-model="invoice.responsible_id" url="user" :source="invoice.source" label="name" />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Дата выставления">
                <b-form-input v-model="invoice.date_bill"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Оплатить до">
                <b-form-input v-model="invoice.date_pay_before"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Сумма">
                <b-form-input v-model="invoice.sum"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Дата оплаты">
                <b-form-input v-model="invoice.date_payed"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div :class="{'col-6': isCompany, 'col-12' : !isCompany}">
              <b-form-group>
                <template #label>
                  <div v-if="isCompany">
                    <b>Компания</b> <a href="" @click.prevent.stop="isCompany = false">Контакт</a>
                  </div>
                  <div v-else>
                    <a href="" @click.prevent.stop="isCompany = true">Компания</a> <b>Контакт</b>
                  </div>
                </template>
                <EntitySelect v-if="!isCompany" v-model="invoice.contact_id" url="sale/contact" :source="invoice.source" />
                <EntitySelect v-else v-model="invoice.company_id" url="sale/company" :source="invoice.source" />
              </b-form-group>
            </div>
            <div v-if="isCompany" class="col-6">
              <b-form-group label="Контакт компании">
                <EntitySelect v-model="invoice.contact_id" url="sale/contact" :source="invoice.source" />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Реквизиты">
                <EntitySelect v-model="invoice.requisite_id" url="sale/requisite" />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Счет организации">
                <b-form-select v-model="invoice.org_invoice_id" :options="orgInvoiceOptions"></b-form-select>
              </b-form-group>
            </div>
          </div>

          <table class="table" style="table-layout: fixed;">
            <tr>
              <th width="25%">Товар</th>
              <th width="30%">Название</th>
              <th width="10%">Кол</th>
              <th width="15%">Цена</th>
              <th width="15%">Сумма</th>
              <th width="5%"></th>
            </tr>
            <tr v-for="product in products" :key="product.id">
              <td><EntitySelect v-model="product.product_id" url="sale/product" :source="invoice.source" /></td>
              <td><b-form-input v-model="product.title"></b-form-input></td>
              <td><b-form-input v-model="product.quantity"></b-form-input></td>
              <td><b-form-input v-model="product.price"></b-form-input></td>
              <td><span v-if="product.quantity && product.price">{{ product.quantity * product.price | money }}</span></td>
              <td><a href="" @click.prevent.stop="products = products.filter(el => el.id !== product.id)">x</a></td>
            </tr>
          </table>
          <a href="" @click.stop.prevent="products.push({})">Добавить</a>

          <div class="row mt-3" v-if="currentId">
            <div class="col-6"><div class="btn btn-success btn-block" @click="save">Сохранить</div></div>
            <div class="col-6"><div class="btn btn-danger btn-block" @click="remove">Удалить</div></div>
          </div>
          <div v-else>
            <div class="btn btn-success btn-block" @click="create">Создать</div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/doc_template.css'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'InvoiceEdit',
  props: ['id'],
  components: {
    VueHtml2pdf
  },
  data () {
    return {
      isLoading: true,
      isLoadingDoc: true,
      error: '',
      errorDoc: '',
      invoice: {},
      products: [],
      doc: '',
      pdfGeneratePercent: 0,
      isCompany: true,
      templateOptions: [
        { value: null, text: 'Не выбрано' }
      ],
      orgInvoiceOptions: [
        { value: null, text: 'Не выбрано' }
      ]
    }
  },
  computed: {
    currentId () {
      return this.id || this.$route.params.id
    }
  },
  watch: {
    $route () {
      this.$vuedals.close()
    }
  },
  async created () {
    const templates = await this.$api.query('doc-template')
    if (templates.data) {
      templates.data.forEach(el => {
        this.templateOptions.push({ value: el.id, text: el.title })
      })
    }
    const orgInvoices = await this.$api.query('fin/org/invoice')
    orgInvoices.forEach(el => {
      this.orgInvoiceOptions.push({ value: el.id, text: el.title })
    })

    if (this.currentId) {
      try {
        this.invoice = await this.$api.query('fin/invoice/' + this.currentId)
        await this.fetchOtherData()
      } catch (e) {
        this.error = 'Счет не найден'
      }

      this.generateDoc()
    } else {
      this.errorDoc = 'Здесь будет шаблон'
      this.isLoadingDoc = false
    }

    this.isLoading = false
  },
  methods: {
    async fetchOtherData () {
      this.isCompany = !!this.invoice.company_id || !this.invoice.contact_id
      this.products = await this.$api.query('fin/invoice/product/' + this.currentId)
    },
    async generateDoc () {
      if (!this.invoice.template_id) {
        this.isLoadingDoc = false
        this.errorDoc = 'Не выбран шаблон документа'
        return
      }
      this.isLoadingDoc = true
      this.errorDoc = ''

      try {
        this.doc = await this.$api.query('doc-template/generate-invoice/' + this.currentId)
      } catch (e) {
        this.errorDoc = e === 'NO_TEMPLATE' ? 'Не выбран шаблон документа' : 'Не удалось сгенерировать документ'
      }

      this.isLoadingDoc = false
    },
    generatePdf () {
      this.$refs.html2Pdf.generatePdf()
    },
    onPdfGenerateProgress (progress) {
      this.pdfGeneratePercent = progress
      if (progress >= 100) {
        setTimeout(() => {
          this.pdfGeneratePercent = 0
        }, 2000)
      }
    },
    async create () {
      if (this.currentId) {
        return
      }
      const newInvoice = await this.$api.post('fin/invoice', this.invoice)
      const invoiceId = newInvoice && newInvoice.id ? newInvoice.id : null
      if (invoiceId) {
        this.products = await this.$api.post('fin/invoice/product/' + invoiceId, this.products)
        await this.$store.dispatch('closePanel')
        this.$store.dispatch('openPanel', { path: '/fin/invoices/' + invoiceId })
      }
    },
    async save () {
      await this.$api.put('fin/invoice/' + this.currentId, this.invoice)
      this.products = await this.$api.post('fin/invoice/product/' + this.currentId, this.products)

      this.generateDoc()
    },
    async remove () {
      if (!confirm('Вы уверены?')) {
        return
      }
      await this.$api.delete('fin/invoice/' + this.currentId)
      await this.$store.dispatch('closePanel')
    },
    async sendOnApproval () {
      this.invoice = await this.$api.put('fin/invoice/send-on-approval/' + this.currentId)
      await this.fetchOtherData()
    },
    async approve () {
      this.invoice = await this.$api.put('fin/invoice/approve/' + this.currentId)
      await this.fetchOtherData()
    }
  }
}
</script>
