<template>
  <div v-if="isLoading"><Loader /></div>
  <div v-else-if="error" class="alert alert-danger">{{ error }}</div>
  <div v-else>
    <table class="table">
      <tr>
        <th>Date</th>
        <th>Sum</th>
        <th>Title</th>
        <th>Client</th>
      </tr>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.date | datetime }}</td>
        <td class="text-right">{{ item.sum | money }}</td>
        <td>{{ item.title }}</td>
        <td>{{ item.contragent }}</td>
      </tr>
      <tr>
        <td><strong>Total:</strong></td>
        <td class="text-right"><strong>{{ total | money }}</strong></td>
        <td colspan="2"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OperationItemsCompact',
  props: {
    dateGroup: String,
    category: [Number, String]
  },
  data () {
    return {
      isLoading: true,
      error: '',
      items: [],
      total: 0
    }
  },
  async created () {
    try {
      this.items = await this.$api.query('fin/operation-item/compact', {
        date_group: this.dateGroup,
        category: this.category
      })

      this.items.forEach(el => { this.total += el.sum })
    } catch (err) {
      this.error = err
    }

    this.isLoading = false
  }
}
</script>

<style scoped>
.table th,
.table td {
  font-size: 12px;
  padding: 5px;
}
</style>
