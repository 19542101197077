<template>
  <div>
    <div class="mx-4">
      <SmartTable
        :sourceUrl="sourceUrl"
        :extSource="extSource"
        :columns="columns"
        :customizeCell="customizeCell"
        dateFilterColumn="created_at" />
    </div>
  </div>
</template>

<script>
import SmartTable from '@/components/SmartTable'
import RouterLinkColumn from '@/components/columns/RouterLinkColumn'
import moment from 'moment'

export default {
  name: 'OperationsList',
  components: {
    SmartTable
  },
  data () {
    return {
      sourceUrl: 'fin/operation',
      extSource: {
        modulbank_account: 'modulbank/account',
        status_list: [
          {
            status: 'SendToBank',
            title: 'Исходящая, ожидающая исполнения'
          },
          {
            status: 'Executed',
            title: 'Исходящий исполненный'
          },
          {
            status: 'RejectByBank',
            title: 'Исходящая, отказано банком в исполнении'
          },
          {
            status: 'Canceled',
            title: 'Исходящая, отправленная в банк и отменённая пользователем'
          },
          {
            status: 'Received',
            title: 'Входящая исполненная'
          }
        ]
      },
      columns: [
        {
          field: 'created_at',
          headerText: 'Дата',
          width: 130,
          valueAccessor (field, data, column) {
            return data[field] ? moment(data[field] * 1000).format('DD.MM.YYYY hh:mm:ss') : ''
          },
          template: () => {
            return {
              template: RouterLinkColumn,
              data: { routerName: 'fin/operations/detail', routerParams: { id: 'id' } }
            }
          }
        },
        {
          field: 'status',
          headerText: 'Статус',
          width: 150,
          foreignKeyValue: 'title',
          foreignKeyField: 'status',
          dataSource: 'status_list'
        },
        {
          field: 'amount',
          headerText: 'Сумма',
          textAlign: 'Right',
          width: 150,
          type: 'number',
          allowFiltering: false
        },
        {
          field: 'description',
          headerText: 'Описание',
          width: 300,
          filter: { type: 'Menu' }
        },
        {
          field: 'contragent_title',
          headerText: 'Контрагент',
          width: 200,
          allowFiltering: false,
          allowSorting: false
        },
        {
          field: 'account_number',
          headerText: 'Банковский счет',
          width: 200,
          foreignKeyValue: 'companyName',
          foreignKeyField: 'number',
          dataSource: 'modulbank_account'
        },
        {
          field: 'oper_item_title',
          headerText: 'Связи',
          width: 200,
          allowFiltering: false,
          allowSorting: false,
          disableHtmlEncode: false
        },
        {
          field: 'org_id',
          headerText: 'Организация',
          width: 200,
          foreignKeyField: 'org_title',
          template: () => {
            return {
              template: RouterLinkColumn,
              data: { routerName: 'fin/settings/orgs/detail', routerParams: { id: 'org_id' } }
            }
          }
        },
        {
          field: 'org_invoice_id',
          headerText: 'Счет',
          width: 200,
          foreignKeyField: 'org_invoice_title',
          template: () => {
            return {
              template: RouterLinkColumn,
              data: { routerName: 'fin/settings/org-invoices/detail', routerParams: { id: 'org_invoice_id' } }
            }
          }
        }
      ]
    }
  },
  watch: {
    $route () {
      this.checkDetail()
    }
  },
  created () {
    this.checkDetail()
  },
  methods: {
    customizeCell: (args) => {
      if (args.column.field === 'contragent_title') {
        if (args.data.contragent_id) {
          switch (args.data.contragent_type) {
            case 'company':
              args.cell.innerHTML = `<a href="https://ranx.bitrix24.ru/crm/company/details/${args.data.contragent_id}/" target="_blank">${args.cell.innerText}</a>`
              break
            case 'contact':
              args.cell.innerHTML = `<a href="https://ranx.bitrix24.ru/crm/contact/details/${args.data.contragent_id}/" target="_blank">${args.cell.innerText}</a>`
              break
          }
        }
      }
    },
    checkDetail () {
      if (this.$route.params.id) {
        this.$store.dispatch('openPanel', { path: this.$route.path })
      }
    }
  }
}
</script>

<style>

</style>
